<template>
  <div class="container text-center">
      <div class="login-container ">
        <b-form class="">
          <!--<img class="mb-4" src="../assets/login-logo.jpg" alt="" width="150" height="150">-->
          <h1 class="h3 mb-3 font-weight-normal">Please Login</h1>
          <label for="username" class="sr-only">Username</label>
          <input type="text" v-model="username" class="form-control" placeholder="Username" required autofocus>

         <div class="input-group  mt-2">
            <input type="password"  v-model="password" class="form-control" placeholder="Password" required>
            <div class="input-group-append">
            <!--  <button class="btn btn-sm btn-outline-secondary" @click="toggleVisibility"><b-icon-eye></b-icon-eye></button> -->
            </div>
        </div>
        <button class="btn btn-lg btn-primary btn-block mt-4" @click="login">Sign in</button>
        <div class="form-signin w-50">
          <p class="text-danger mt-4">{{errorMessage}}</p>
        </div>
      </b-form>  


       


          <p class="text-muted">Creative Connections &copy; {{ currentYear }} </p>            
      </div>
  </div>
</template>

<script>
import {RepositoryFactory} from '../api/RepositoryFactory'
const RepoPost = RepositoryFactory.post('registration')
import moment from 'moment'
export default {
  name: 'LoginAdmin',
  props: {

  },
  data(){
      return {
        currentYear: moment().year(),
        passwordFieldType: 'password',
          password: "",
          username: "", 
          errorMessage: "",
      }
  },
  methods:{
    async getData(){
        let formData = new FormData()
        formData.append('username', this.username);
        formData.append('password', this.password);
        formData.append('appKey', '6A26879E-DEE9-4B81-B15C-BA6861E44532');
       // formData.append('appKey', '3DAD18C9-2B71-45D9-A200-376BAC075418');
        const {data} = await RepoPost.login(formData)
        
        console.log(typeof(data))

        if(data != 'forbidden')
        {
          this.$store.commit('logIn', {secToken : data})
          this.$nextTick(()=>{
              window.location = ('/dashboard')
          })
        }
        else
        {
          this.errorMessage = "Invalid Login"
        }
    },
    login(e)
    {
      e.preventDefault()
      this.getData()

		},

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container{
  width: 100%;
}

.login-container
{
  width: 70%;
  margin: 20px Auto
}

@media (min-width: 768px) { 

.login-container
{
  width: 25%;
  margin: 20px Auto
}

}
</style>
